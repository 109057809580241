const tabData = [
    {
        "Company": "Super Micro Computer, Inc.",
        "Symbol": "SMCI*",
        "Picked": "11/15/2022",
        "Purchase": "85.25",
        "Closed": "3/19/2024",
        "Sell_Price": "910.77",
        "Return": "968.59%",
        "SPY": "28.56%",
        "Diff": "940.03%"
    },
    {
        "Company": "Super Micro Computer, Inc.",
        "Symbol": "SMCI",
        "Picked": "11/15/2022",
        "Purchase": "85.25",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "632.73%",
        "SPY": "32.84%",
        "Diff": "599.88%"
    },
    {
        "Company": "Modine Manufacturing Co.",
        "Symbol": "MOD",
        "Picked": "12/15/2022",
        "Purchase": "20.59",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "379.84%",
        "SPY": "35.15%",
        "Diff": "344.69%"
    },
    {
        "Company": "M/I Homes, Inc.",
        "Symbol": "MHO",
        "Picked": "10/3/2022",
        "Purchase": "36.92",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "316.87%",
        "SPY": "47.59%",
        "Diff": "269.29%"
    },
    {
        "Company": "Powell Industries, Inc.",
        "Symbol": "POWL",
        "Picked": "5/15/2023",
        "Purchase": "54.65",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "180.81%",
        "SPY": "31.62%",
        "Diff": "149.19%"
    },
    {
        "Company": "Abercrombie & Fitch Co.",
        "Symbol": "ANF",
        "Picked": "10/2/2023",
        "Purchase": "56.95",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "132.13%",
        "SPY": "26.21%",
        "Diff": "105.92%"
    },
    {
        "Company": "Alpha Metallurgical Resources, Inc.",
        "Symbol": "AMR",
        "Picked": "7/1/2022",
        "Purchase": "127.13",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "104.92%",
        "SPY": "41.52%",
        "Diff": "63.41%"
    },
    {
        "Company": "Celestica Inc.",
        "Symbol": "CLS",
        "Picked": "10/16/2023",
        "Purchase": "27.30",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "69.56%",
        "SPY": "24.12%",
        "Diff": "45.44%"
    },
    {
        "Company": "Sterling Infrastructure, Inc.",
        "Symbol": "STRL",
        "Picked": "8/1/2023",
        "Purchase": "62.43",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "68.03%",
        "SPY": "18.43%",
        "Diff": "49.60%"
    },
    {
        "Company": "Modine Manufacturing Co.",
        "Symbol": "MOD",
        "Picked": "1/2/2024",
        "Purchase": "59.77",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "65.30%",
        "SPY": "13.58%",
        "Diff": "51.72%"
    },
    {
        "Company": "AppLovin Corporation",
        "Symbol": "APP",
        "Picked": "11/15/2023",
        "Purchase": "42.44",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "61.92%",
        "SPY": "19.63%",
        "Diff": "42.30%"
    },
    {
        "Company": "Nucor Corporation",
        "Symbol": "NUE",
        "Picked": "7/1/2022",
        "Purchase": "104.24",
        "Closed": "7/24/2023",
        "Sell_Price": "166.65",
        "Return": "59.87%",
        "SPY": "20.62%",
        "Diff": "39.25%"
    },
    {
        "Company": "Meta Platforms, Inc.",
        "Symbol": "META",
        "Picked": "8/15/2023",
        "Purchase": "306.14",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "59.45%",
        "SPY": "21.08%",
        "Diff": "38.37%"
    },
    {
        "Company": "Uber Technologies, Inc.",
        "Symbol": "UBER",
        "Picked": "6/1/2023",
        "Purchase": "37.50",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "57.31%",
        "SPY": "29.81%",
        "Diff": "27.49%"
    },
    {
        "Company": "TravelCenters of America Inc.",
        "Symbol": "TA",
        "Picked": "8/15/2022",
        "Purchase": "53.82",
        "Closed": "2/16/2023",
        "Sell_Price": "84.43",
        "Return": "56.87%",
        "SPY": "-3.88%",
        "Diff": "60.76%"
    },
    {
        "Company": "Marathon Petroleum Corporation",
        "Symbol": "MPC",
        "Picked": "1/3/2023",
        "Purchase": "115.65",
        "Closed": "7/29/2024",
        "Sell_Price": "171.96",
        "Return": "48.69%",
        "SPY": "41.73%",
        "Diff": "6.96%"
    },
    {
        "Company": "United Rentals, Inc.",
        "Symbol": "URI",
        "Picked": "2/15/2023",
        "Purchase": "451.78",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "48.62%",
        "SPY": "32.66%",
        "Diff": "15.96%"
    },
    {
        "Company": "Manulife Financial Corporation",
        "Symbol": "MFC",
        "Picked": "11/1/2023",
        "Purchase": "17.60",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "40.57%",
        "SPY": "28.45%",
        "Diff": "12.12%"
    },
    {
        "Company": "Valero Energy Corporation",
        "Symbol": "VLO",
        "Picked": "7/1/2022",
        "Purchase": "107.79",
        "Closed": "7/15/2024",
        "Sell_Price": "148.75",
        "Return": "38.00%",
        "SPY": "49.12%",
        "Diff": "-11.12%"
    },
    {
        "Company": "Corporación América Airports S.A.",
        "Symbol": "CAAP",
        "Picked": "5/1/2023",
        "Purchase": "11.31",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "33.95%",
        "SPY": "30.49%",
        "Diff": "3.47%"
    },
    {
        "Company": "T-Mobile US, Inc.",
        "Symbol": "TMUS",
        "Picked": "9/15/2023",
        "Purchase": "143.97",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "31.11%",
        "SPY": "20.42%",
        "Diff": "10.69%"
    },
    {
        "Company": "ConocoPhillips",
        "Symbol": "COP",
        "Picked": "7/1/2022",
        "Purchase": "91.93",
        "Closed": "8/1/2023",
        "Sell_Price": "117.38",
        "Return": "30.25%",
        "SPY": "21.22%",
        "Diff": "9.03%"
    },
    {
        "Company": "Green Brick Partners, Inc.",
        "Symbol": "GRBK",
        "Picked": "7/17/2023",
        "Purchase": "57.24",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "25.42%",
        "SPY": "20.31%",
        "Diff": "5.11%"
    },
    {
        "Company": "M/I Homes, Inc.",
        "Symbol": "MHO",
        "Picked": "12/15/2023",
        "Purchase": "123.72",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "24.40%",
        "SPY": "14.23%",
        "Diff": "10.18%"
    },
    {
        "Company": "Sprouts Farmers Market, Inc.",
        "Symbol": "SFM",
        "Picked": "6/17/2024",
        "Purchase": "78.97",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "23.68%",
        "SPY": "-1.38%",
        "Diff": "25.06%"
    },
    {
        "Company": "BlueLinx Holdings Inc.",
        "Symbol": "BXC",
        "Picked": "7/1/2022",
        "Purchase": "73.09",
        "Closed": "7/1/2024",
        "Sell_Price": "89.18",
        "Return": "22.01%",
        "SPY": "42.55%",
        "Diff": "-20.54%"
    },
    {
        "Company": "Alphabet Inc.",
        "Symbol": "GOOGL",
        "Picked": "9/1/2023",
        "Purchase": "137.46",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "21.25%",
        "SPY": "19.24%",
        "Diff": "2.01%"
    },
    {
        "Company": "Salesforce, Inc.",
        "Symbol": "CRM",
        "Picked": "6/15/2023",
        "Purchase": "207.95",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "17.32%",
        "SPY": "24.24%",
        "Diff": "-6.91%"
    },
    {
        "Company": "Brinker International, Inc.",
        "Symbol": "EAT",
        "Picked": "4/1/2024",
        "Purchase": "51.25",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "15.77%",
        "SPY": "2.06%",
        "Diff": "13.71%"
    },
    {
        "Company": "Terex Corporation",
        "Symbol": "TEX",
        "Picked": "1/16/2023",
        "Purchase": "48.12",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "13.03%",
        "SPY": "33.73%",
        "Diff": "-20.70%"
    },
    {
        "Company": "Tecnoglass Inc.",
        "Symbol": "TGLS",
        "Picked": "4/3/2023",
        "Purchase": "43.26",
        "Closed": "2/5/2024",
        "Sell_Price": "46.83",
        "Return": "8.38%",
        "SPY": "20.44%",
        "Diff": "-12.06%"
    },
    {
        "Company": "Jackson Financial Inc.",
        "Symbol": "JXN",
        "Picked": "2/1/2023",
        "Purchase": "45.01",
        "Closed": "1/16/2024",
        "Sell_Price": "48.65",
        "Return": "8.20%",
        "SPY": "16.55%",
        "Diff": "-8.36%"
    },
    {
        "Company": "Royal Caribbean Cruises Ltd.",
        "Symbol": "RCL",
        "Picked": "3/15/2024",
        "Purchase": "128.75",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "7.84%",
        "SPY": "4.78%",
        "Diff": "3.06%"
    },
    {
        "Company": "PepsiCo, Inc.",
        "Symbol": "PEP",
        "Picked": "1/16/2024",
        "Purchase": "167.27",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "6.44%",
        "SPY": "12.84%",
        "Diff": "-6.40%"
    },
    {
        "Company": "Ardmore Shipping Corporation",
        "Symbol": "ASC",
        "Picked": "3/1/2023",
        "Purchase": "18.70",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "6.10%",
        "SPY": "37.74%",
        "Diff": "-31.64%"
    },
    {
        "Company": "Berkshire Hathaway Inc.",
        "Symbol": "BRK.B",
        "Picked": "7/1/2024",
        "Purchase": "408.09",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "4.97%",
        "SPY": "-2.33%",
        "Diff": "7.30%"
    },
    {
        "Company": "Exxon Mobil Corporation",
        "Symbol": "XOM",
        "Picked": "10/17/2022",
        "Purchase": "101.07",
        "Closed": "1/26/2024",
        "Sell_Price": "103.00",
        "Return": "1.91%",
        "SPY": "33.90%",
        "Diff": "-31.99%"
    },
    {
        "Company": "Suncor Energy Inc.",
        "Symbol": "SU",
        "Picked": "8/1/2022",
        "Purchase": "33.44",
        "Closed": "10/23/2023",
        "Sell_Price": "33.51",
        "Return": "0.21%",
        "SPY": "2.76%",
        "Diff": "-2.55%"
    },
    {
        "Company": "Chevron Corporation",
        "Symbol": "CVX",
        "Picked": "9/1/2022",
        "Purchase": "156.48",
        "Closed": "5/12/2023",
        "Sell_Price": "156.62",
        "Return": "0.09%",
        "SPY": "4.76%",
        "Diff": "-4.67%"
    },
    {
        "Company": "Adtalem Global Education Inc.",
        "Symbol": "ATGE",
        "Picked": "7/15/2024",
        "Purchase": "76.91",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-1.61%",
        "SPY": "-5.18%",
        "Diff": "3.57%"
    },
    {
        "Company": "Okta, Inc.",
        "Symbol": "OKTA",
        "Picked": "2/15/2024",
        "Purchase": "89.36",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-2.99%",
        "SPY": "7.41%",
        "Diff": "-10.40%"
    },
    {
        "Company": "Arch Resources, Inc.",
        "Symbol": "ARCH",
        "Picked": "7/1/2022",
        "Purchase": "143.09",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-5.51%",
        "SPY": "41.52%",
        "Diff": "-47.02%"
    },
    {
        "Company": "Comcast Corporation",
        "Symbol": "CMCSA",
        "Picked": "12/1/2023",
        "Purchase": "42.25",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-5.55%",
        "SPY": "18.14%",
        "Diff": "-23.69%"
    },
    {
        "Company": "Axcelis Technologies, Inc.",
        "Symbol": "ACLS",
        "Picked": "4/17/2023",
        "Purchase": "125.69",
        "Closed": "2/16/2024",
        "Sell_Price": "116.78",
        "Return": "-7.30%",
        "SPY": "21.10%",
        "Diff": "-28.40%"
    },
    {
        "Company": "General Motors Company",
        "Symbol": "GM",
        "Picked": "5/1/2024",
        "Purchase": "44.50",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-7.48%",
        "SPY": "6.63%",
        "Diff": "-14.11%"
    },
    {
        "Company": "Reinsurance Group of America, Incorporated",
        "Symbol": "RGA",
        "Picked": "8/1/2024",
        "Purchase": "226.68",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-8.99%",
        "SPY": "-",
        "Diff": "-8.99%"
    },
    {
        "Company": "SkyWest, Inc.",
        "Symbol": "SKYW",
        "Picked": "6/3/2024",
        "Purchase": "80.01",
        "Closed": "-",
        "Sell_Price": "-",
        "Return": "-10.24%",
        "SPY": "1.06%",
        "Diff": "-11.30%"
    },
    {
        "Company": "Cardinal Health, Inc.",
        "Symbol": "CAH",
        "Picked": "3/1/2024",
        "Purchase Price": "114.45",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-11.23%",
        "SPY": "5.36%",
        "Diff": "-16.59%"
    },
    {
        "Company": "Blue Bird Corporation",
        "Symbol": "BLBD",
        "Picked": "5/15/2024",
        "Purchase Price": "52.83",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-11.83%",
        "SPY": "1.67%",
        "Diff": "-13.50%"
    },
    {
        "Company": "Twilio Inc.",
        "Symbol": "TWLO",
        "Picked": "2/1/2024",
        "Purchase Price": "71.78",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-12.42%",
        "SPY": "10.66%",
        "Diff": "-23.08%"
    },
    {
        "Company": "GigaCloud Technology Inc.",
        "Symbol": "GCT",
        "Picked": "4/15/2024",
        "Purchase Price": "33.36",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-23.39%",
        "SPY": "3.78%",
        "Diff": "-27.17%"
    },
    {
        "Company": "HF Sinclair Corporation",
        "Symbol": "DINO",
        "Picked": "12/1/2022",
        "Purchase Price": "63.04",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-25.82%",
        "SPY": "30.37%",
        "Diff": "-56.19%"
    },
    {
        "Company": "Amphastar Pharmaceuticals, Inc.",
        "Symbol": "AMP",
        "Picked": "7/3/2023",
        "Purchase Price": "58.75",
        "Closed": "-",
        "Sell Price": "-",
        "Return": "-29.24%",
        "SPY": "22.00%",
        "Diff": "-51.24%"
    },
    {
        "Company": "Perion Network Ltd.",
        "Symbol": "PERI",
        "Picked": "3/15/2023",
        "Purchase Price": "34.30",
        "Closed": "2/9/2024",
        "Sell Price": "23.89",
        "Return": "-30.63%",
        "SPY": "29.78%",
        "Diff": "-60.41%"
    },
    {
        "Company": "Livent Corporation",
        "Symbol": "LTHM",
        "Picked": "9/15/2022",
        "Purchase Price": "14.38",
        "Closed": "5/25/2023",
        "Sell Price": "9.81",
        "Return": "-30.90%",
        "SPY": "6.89%",
        "Diff": "-37.79%"
    },
    {
        "Company": "Harmonic Inc.",
        "Symbol": "HLIT",
        "Picked": "11/1/2022",
        "Purchase Price": "15.60",
        "Closed": "8/7/2023",
        "Sell Price": "10.64",
        "Return": "-31.79%",
        "SPY": "15.53%",
        "Diff": "-47.32%"
    }

];

export default tabData;
