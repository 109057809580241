const tabData = [
    {
        Company: "Berkshire Hathaway Inc.",
        Symbol: 'BRK.B',
        Picked: '7/1/2024',
        Return: '4.97%',
        Sector: 'Financials',
        Rating: 'STRONG BUY', // STRONG BUY深绿色,BUY 浅绿  HOLD 黄色
        Holding: '1.32%',
    },
    {
        "Company": "Abercrombie & Fitch Co.",
        "Symbol": "ANF",
        "Picked": "10/2/2023",
        "Return": "132.13%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "3.51%",
    },
    {
        "Company": "General Motors Company",
        "Symbol": "GM",
        "Picked": "5/1/2024",
        "Return": "-7.48%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "1.23%"
    },
    {
        "Company": "Powell Industries, Inc.",
        "Symbol": "POWL",
        "Picked": "5/15/2023",
        "Return": "180.81%",
        "Sector": "Industrials",
        "Rating": "STRONG BUY",
        "Holding": "5.18%"
    },
    {
        "Company": "AppLovin Corporation",
        "Symbol": "APP",
        "Picked": "11/15/2023",
        "Return": "61.92%",
        "Sector": "Information Technology",
        "Rating": "STRONG BUY",
        "Holding": "2.64%"
    },
    {
        "Company": "SkyWest, Inc.",
        "Symbol": "SKYW",
        "Picked": "6/3/2024",
        "Return": "-10.24%",
        "Sector": "Industrials",
        "Rating": "STRONG BUY",
        "Holding": "1.23%"
    },
    {
        "Company": "Brinker International, Inc.",
        "Symbol": "EAT",
        "Picked": "4/1/2024",
        "Return": "15.77%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "1.56%"
    },
    {
        "Company": "Blue Bird Corporation",
        "Symbol": "BLBD",
        "Picked": "5/15/2024",
        "Return": "-11.83%",
        "Sector": "Industrials",
        "Rating": "STRONG BUY",
        "Holding": "1.23%"
    },
    {
        "Company": "Celestica Inc.",
        "Symbol": "CLS",
        "Picked": "10/16/2023",
        "Return": "69.56%",
        "Sector": "Information Technology",
        "Rating": "STRONG BUY",
        "Holding": "2.84%"
    },
    {
        "Company": "Super Micro Computer, Inc.",
        "Symbol": "SMCI",
        "Picked": "11/15/2022",
        "Return": "632.73%",
        "Sector": "Information Technology",
        "Rating": "STRONG BUY",
        "Holding": "6.78%"
    },
    {
        "Company": "Reinsurance Group of America, Incorporated",
        "Symbol": "RGA",
        "Picked": "8/1/2024",
        "Return": "-8.99%",
        "Sector": "Financials",
        "Rating": "STRONG BUY",
        "Holding": "1.06%"
    },
    {
        "Company": "Ardmore Shipping Corporation",
        "Symbol": "ASC",
        "Picked": "3/1/2023",
        "Return": "6.10%",
        "Sector": "Energy",
        "Rating": "STRONG BUY",
        "Holding": "2.59%"
    },
    {
        "Company": "Royal Caribbean Cruises Ltd.",
        "Symbol": "RCL",
        "Picked": "3/15/2024",
        "Return": "7.84%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "1.65%"
    },
    {
        "Company": "Adtalem Global Education Inc.",
        "Symbol": "ATGE",
        "Picked": "7/15/2024",
        "Return": "-1.61%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "1.21%"
    },
    {
        "Company": "M/I Homes, Inc.",
        "Symbol": "MHO",
        "Picked": "10/3/2022",
        "Return": "316.87%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "6.37%"
    },
    {
        "Company": "M/I Homes, Inc.",
        "Symbol": "MHO",
        "Picked": "12/15/2023",
        "Return": "24.40%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "2.15%"
    },
    {
        "Company": "Green Brick Partners, Inc.",
        "Symbol": "GRBK",
        "Picked": "7/17/2023",
        "Return": "25.42%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "2.40%"
    },
    {
        "Company": "Sterling Infrastructure, Inc.",
        "Symbol": "STRL",
        "Picked": "8/1/2023",
        "Return": "68.03%",
        "Sector": "Industrials",
        "Rating": "STRONG BUY",
        "Holding": "2.92%"
    },
    {
        "Company": "Okta, Inc.",
        "Symbol": "OKTA",
        "Picked": "2/15/2024",
        "Return": "-2.99%",
        "Sector": "Information Technology",
        "Rating": "STRONG BUY",
        "Holding": "1.47%"
    },
    {
        "Company": "GigaCloud Technology Inc.",
        "Symbol": "GCT",
        "Picked": "4/15/2024",
        "Return": "-23.39%",
        "Sector": "Consumer Discretionary",
        "Rating": "STRONG BUY",
        "Holding": "1.11%"
    },
    {
        "Company": "Twilio Inc.",
        "Symbol": "TWLO",
        "Picked": "2/1/2024",
        "Return": "-12.42%",
        "Sector": "Information Technology",
        "Rating": "STRONG BUY",
        "Holding": "1.31%"
    },
    {
        "Company": "Manulife Financial Corporation",
        "Symbol": "MFC",
        "Picked": "11/1/2023",
        "Return": "40.57%",
        "Sector": "Financials",
        "Rating": "STRONG BUY",
        "Holding": "2.28%"
    },
    {
        "Company": "Corporación América Airports S.A.",
        "Symbol": "CAAP",
        "Picked": "5/1/2023",
        "Return": "33.95%",
        "Sector": "Industrials",
        "Rating": "BUY",
        "Holding": "2.44%"
    },
    {
        "Company": "PepsiCo, Inc.",
        "Symbol": "PEP",
        "Picked": "1/16/2024",
        "Return": "6.44%",
        "Sector": "Consumer Staples",
        "Rating": "BUY",
        "Holding": "1.69%"
    },
    {
        "Company": "Terex Corporation",
        "Symbol": "TEX",
        "Picked": "1/16/2023",
        "Return": "13.03%",
        "Sector": "Industrials",
        "Rating": "BUY",
        "Holding": "2.49%"
    },
    {
        "Company": "Sprouts Farmers Market, Inc.",
        "Symbol": "SFM",
        "Picked": "6/17/2024",
        "Return": "23.68%",
        "Sector": "Consumer Staples",
        "Rating": "HOLD",
        "Holding": "1.55%"
    },
    {
        "Company": "Meta Platforms, Inc.",
        "Symbol": "META",
        "Picked": "8/15/2023",
        "Return": "59.45%",
        "Sector": "Communication Services",
        "Rating": "HOLD",
        "Holding": "2.30%"
    },
    {
        "Company": "Alphabet Inc.",
        "Symbol": "GOOGL",
        "Picked": "9/1/2023",
        "Return": "21.25%",
        "Sector": "Communication Services",
        "Rating": "HOLD",
        "Holding": "2.00%"
    },
    {
        "Company": "T-Mobile US, Inc.",
        "Symbol": "TMUS",
        "Picked": "9/15/2023",
        "Return": "31.11%",
        "Sector": "Communication Services",
        "Rating": "HOLD",
        "Holding": "2.04%"
    },
    {
        "Company": "Modine Manufacturing Company",
        "Symbol": "MOD",
        "Picked": "12/15/2022",
        "Return": "379.84%",
        "Sector": "Consumer Discretionary",
        "Rating": "HOLD",
        "Holding": "8.40%"
    },
    {
        "Company": "Salesforce, Inc.",
        "Symbol": "CRM",
        "Picked": "6/15/2023",
        "Return": "17.32%",
        "Sector": "Information Technology",
        "Rating": "HOLD",
        "Holding": "2.11%"
    },
    {
        "Company": "Comcast Corporation",
        "Symbol": "CMCSA",
        "Picked": "12/1/2023",
        "Return": "-5.55%",
        "Sector": "Communication Services",
        "Rating": "HOLD",
        "Holding": "1.61%"
    },
    {
        "Company": "Cardinal Health, Inc.",
        "Symbol": "CAH",
        "Picked": "3/1/2024",
        "Return": "-11.23%",
        "Sector": "Health Care",
        "Rating": "HOLD",
        "Holding": "1.27%"
    },
    {
        "Company": "United Rentals, Inc.",
        "Symbol": "URI",
        "Picked": "2/15/2023",
        "Return": "48.62%",
        "Sector": "Industrials",
        "Rating": "HOLD",
        "Holding": "3.17%"
    },
    {
        "Company": "Uber Technologies, Inc.",
        "Symbol": "UBER",
        "Picked": "6/1/2023",
        "Return": "57.31%",
        "Sector": "Industrials",
        "Rating": "HOLD",
        "Holding": "2.65%"
    },
    {
        "Company": "HF Sinclair Corporation",
        "Symbol": "DINO",
        "Picked": "12/1/2022",
        "Return": "-25.82%",
        "Sector": "Energy",
        "Rating": "HOLD",
        "Holding": "1.87%"
    },
    {
        "Company": "Alpha Metallurgical Resources, Inc.",
        "Symbol": "AMR",
        "Picked": "7/1/2022",
        "Return": "104.92%",
        "Sector": "Materials",
        "Rating": "HOLD",
        "Holding": "3.77%"
    },
    {
        "Company": "Arch Resources, Inc.",
        "Symbol": "ARCH",
        "Picked": "7/1/2022",
        "Return": "-5.51%",
        "Sector": "Materials",
        "Rating": "HOLD",
        "Holding": "2.31%"
    },
    {
        "Company": "Amphastar Pharmaceuticals, Inc.",
        "Symbol": "AMPH",
        "Picked": "7/3/2023",
        "Return": "-29.24%",
        "Sector": "Health Care",
        "Rating": "HOLD",
        "Holding": "1.49%"
    }

];

export default tabData;
