<template>
  <Header1 :selectFlag="headerSelectFlag"></Header1>
  <RouterView></RouterView>

  <BottomView></BottomView>
</template>
<script >

import Header1 from "../components/header.vue";
import BottomView from "@/components/BottomView.vue";

export default {
  name: "defHome",
  components: {BottomView, Header1},
  data(){
    return {
      headerSelectFlag:"about"
    };
  },
  created() {


  }

}
</script>

<style scoped lang="scss">



</style>
