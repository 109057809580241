import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory} from 'vue-router'


import Home from "@/home/Home.vue";
import DefHome from "@/home/defHome.vue";
import aboutUs from "@/views/aboutUs/index.vue";
import AlphaPicks from "@/views/AlphaPicks/index.vue";
import news from "@/views/news/index.vue";



const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/', component: Home},
        {
            path: '/aboutUs',
            component: DefHome,
            children:[
                {
                    name:"aboutUs-about",
                    path:"about",
                    component: aboutUs,
                }
            ],
        },
        {
            path: '/AlphaPicks',
            component: DefHome,
            children:[
                {
                    name:"AlphaPicks",
                    path:"AlphaPicks",
                    component: AlphaPicks,
                }
            ],
        },
        {
            path: '/MarketInterpretation',
            component: DefHome,
            children:[
                {
                    name:"MarketInterpretation",
                    path:"",
                    component: news,
                }
            ],
        },
        {
            path: '/news',
            component: DefHome,
            children:[
                {
                    name:"news",
                    path:"",
                    component: news,
                }
            ],
        },
    ],
})

export default router;

