<template>
  <div class="header-views flex align-center">
    <div class="header-body def-max-width flex ">
      <div class="left-views">
        <img class="logo-view" src="../assets/logo_tran.png" style="filter: invert(100%) brightness(100%)" alt=""/>
      </div>
      <div class="right-views flex  align-center" :class="[isSmall && showMenu ? 'visible':'hidden']">
        <div v-for="(item,index) in pages" :key="index" @click="onClickTo(item)" class="item"
        ><!--:class="{active:selectFlag == item.path}"-->
          <template v-if="item.child">
            <el-dropdown  :trigger="'hover'">
              <span class="el-dropdown-link">
                {{ item.label }}
                <el-icon class="el-icon--right">
                  <arrow-down/>
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="onClickTo(item1)" v-for="(item1,index1) in item.child" :key="index1">
                    {{ item1.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
          <template v-else>
            {{ item.label }}
          </template>
        </div>
      </div>
      <div class="menu-icon-views flex align-center" @click="()=>{
        showMenu = !showMenu;
      }">
        <el-icon color="#9B9D9E" size="2rem">
          <Menu/>
        </el-icon>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import links from "@/consts/links";

export default {
  props: {
    selectFlag: {
      type: String,       // 期待的类型
      required: true,     // 是否是必需的
      default: 'home', // 默认值
    },

  },
  name: "header",
  data() {
    return {
      showMenu: false,
      pages: links
    }
  },
  computed: {
    ...mapState(['isSmall'])
  },
  methods: {
    onClickTo(pageItem) {
      if (this.$common.isBlank(pageItem.child)) {
        const pushObj = {};

        if(pageItem.outLink){
          window.open(pageItem.outLink, '_blank');
        }else if (this.$common.isBlank(pageItem.pathName)) {
          pushObj.path = pageItem.path
        } else {
          pushObj.name = pageItem.pathName
        }

        if (pageItem.query) {
          pushObj.query = pageItem.query;
        }


        this.$router.push(pushObj)
        this.showMenu = false;
      }
    }
  },
  created() {

  }
}
</script>
<style lang="scss" scoped>
.header-body {
  padding: 0 2rem;
}

.header-views {
  background: #000000;
  height: 8.7rem;
}

.left-views {
  margin-right: 2rem;

  .logo-view {
    width: 12.9rem;
    //height: 3.4rem;
  }
}

.right-views {
  flex: 1;
  color: #fff;
  z-index: 2000;

  .item {
    cursor: pointer;
    font-size: 1rem;
    margin-right: 1.5rem;
    color: #fff;

    & > .el-dropdown {
      color: inherit;
      font-size: inherit;

    }

    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    color: #DDA871;
  }
}

.menu-icon-views {
  display: none;
  border: 1px solid #5E5E5E;
  border-radius: .5rem;
  padding: 1rem;
  margin-left: 3rem;
}


@media screen and (max-width: 768px) {


  .header-body {
    flex-wrap: wrap;
    justify-content: space-between;
  }


  .right-views {
    flex: 1 0 100%;
    order: 1;
    flex-direction: column;
    position: absolute;
    top: 8.7rem;
    background: #212529;
    left: 0;
    right: 0;
    align-items: flex-start;
    transition: opacity 0.5s ease;
    max-height: 0; /* 默认隐藏 */
    overflow: hidden;

    &.visible {
      max-height: 500px; /* 设置一个足够大的最大高度 */
      opacity: 1;
    }

    &.hidden {
      max-height: 0;
      opacity: 0;
    }


    .item {
      font-size: 2.3rem;
      margin-bottom: 1.2rem;
      margin-top: .2rem;
      margin-left: 2rem;
    }

  }

  .menu-icon-views {
    display: flex;
  }


}

</style>
