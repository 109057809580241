<template>
  <Header1 selectFlag="/"></Header1>

  <div class="video-views flex justify-center align-center">
    <video loop="loop" src="../assets/home/ourVision.mp4" poster="" autoplay="autoplay"
           webkit-playsinline="" playsinline="" muted
           style="object-fit: cover;" class="video-view"></video>
  </div>

  <!--  学院描述-->
  <div class="def-max-width div-1-views flex flex-direction ">
    <div class="title-1">
      North American Institute of Wealth Strategy（NAIWS）
    </div>
    <div class="msg-views flex flex-direction">
      <div class="txt-1">
        Dedicated to training top traders globally, making trading easier and profits simpler.
      </div>
      <img src="../assets/home/div-1-img.png" class="img" alt=""/>
    </div>

    <!--   4大优势 -->
    <div class="msg-1-views">
      <div class="title-2-view flex flex-direction">
        <div class="txt-2">
          Four Major Functions
        </div>
        <div class="txt-3">
          of Al Super Profit
        </div>

        <div class="icon-views">
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
        </div>
      </div>
      <div class="youShi-views flex justify-around">
        <div class="item">
          <img src="../assets/home/youShi-icon-1.png" alt="" class="youShi-icon">
          <div class="title">
            Trading Signal Decision
          </div>
          <div class="content">
            Assists in subjective judgment providing real-time buying and selling signals with an accuracy rate
            exceeding 89.7%.
          </div>
        </div>
        <div class="item">
          <img src="../assets/home/youShi-icon-2.png" alt="" class="youShi-icon">
          <div class="title">
            Artificial Intelligence ProgrammedTrading
          </div>
          <div class="content">
            A computerized trading system based on artificial intelligence. Once parameters are manually adjusted, it
            automatically executes trades, bringing stable profits.
          </div>
        </div>
        <div class="item">
          <img src="../assets/home/youShi-icon-3.png" alt="" class="youShi-icon">
          <div class="title">
            Investment Strategy Decision
          </div>
          <div class="content">
            An AI decision-making system analyzes data, forecasts trends, assesses stock performance, manages risk, and
            optimizes portfolios, empowering traders to make informed, precise, and timely investment decisions in
            volatile markets.
          </div>
        </div>
        <div class="item">
          <img src="../assets/home/youShi-icon-4.png" alt="" class="youShi-icon">
          <div class="title">
            Expert and Investment Advisor
          </div>
          <div class="content">
            A precise and powerful investment advisory system composed of numerous renowned investment experts,
            assisting high-quality users and future funds in making wise investment decisions and plans.
          </div>
        </div>
      </div>
    </div>

    <!--  我们的愿景  -->
    <div class="msg-2-views">
      <div class="title-2-view flex flex-direction">
        <div class="txt-2">
          Our Vision:
        </div>
        <div class="txt-3">
          Achieve Wealth Growth, Attain Leading Excellence.
        </div>

        <div class="icon-views">
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
        </div>
      </div>

      <div class="yuanJin-msg-views flex flex-direction align-stretch">
        <div class="yuanJin-msg-views-1 flex">
          <div class="left-views flex flex-direction align-stretch justify-between">
            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Promote the widespread accessibility and universalization of
                financialeducation.
              </div>
            </div>
            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Establish a globally leading trading training system.
              </div>
            </div>
            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Nurture top-tier trading experts and financial market.
              </div>
            </div>
            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Leaders achieve mutual development between indviduals and
                society
              </div>
            </div>
            <div class="chengLuo-button-view button-view flex justify-center align-center">
              OVER 15 YEARS OF STEADFAST COMMITMENT.
            </div>
          </div>
          <div class="right-views" style="margin-left: 2rem;">
            <img alt="" src="../assets/home/yuanJin-1.png" class="yuanJin-img-1"/>
            <div class="yuanJin-img-1-right-view flex flex-direction justify-between align-stretch"
                 style="flex: 0 0 48%;">
              <img alt="" src="../assets/home/yuanJin-2.png" class="yuanJin-img-1" style="width: 100%"/>
              <div class="yuanJin-xueShengNum button-view flex align-center">
                <img src="../assets/home/xueShengNum-icon.png" alt="" class="xueShengNum-icon-img">
                <div class="xueSheng-txt-1 flex flex-direction">
                  <div>300K+</div>
                  <div>Students:</div>
                </div>
                <div class="xueSheng-txt-2 flex flex-direction">
                  <div>More than</div>
                  <div>300,000 Students</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="yuanJin-msg-views-2 flex">
          <div class="left-views  flex flex-direction align-stretch justify-between">
            <div class="title">
              Proposed launch <br/>
              of the Al super Profit APP
            </div>

            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Explore the best trading or investment tools Making
              </div>
            </div>
            <div class="ul-item">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Capitalization.<br/>
                Artificial Intelligence: The ultimate trading companion
              </div>
            </div>
            <img alt="" src="../assets/home/yuanJin-4.png" style="width: 100%;margin-top: 2rem;"/>
          </div>
          <div class="right-views flex-direction">
            <img alt="" src="../assets/home/yuanJin-3.png" style="width: 100%;margin-bottom: 2rem;"/>
            <div class="title" style="margin-left: 2rem;">
              Proposed launch <br/>
              of the Al super Profit APP
            </div>

            <div class="ul-item" style="margin-left: 2rem;">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Explore the best trading or investment tools Making
              </div>
            </div>
            <div class="ul-item" style="margin-left: 2rem;">
              <img src="../assets/home/dian-icon.png" alt="" class="dian-icon">
              <div>
                Capitalization.<br/>
                Artificial Intelligence: The ultimate trading companion
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <!--     市场 交易员和投资者   -->
    <div class="shiChang-jiaoYiYuan-touZhiZhe-views">
      <div class="title-2-view flex flex-direction">
        <div class="txt-2">
          Market place for
        </div>
        <div class="txt-3">
          traders and investors
        </div>

        <div class="icon-views">
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
        </div>
      </div>
      <div class="shiChang-views flex justify-between flex-wrap">
        <div class="item">
          <div class="title">
            Stocks Portfolio Wizards
          </div>
          <div class="content">
            Create well-viewersified portfolios based on your investment criteria with help of our proprietary algorithms
          </div>
        </div>
        <div class="item">
          <div class="title">
            Al Active Portfolios
          </div>
          <div class="content">
            Active investing in stocks & ETFs in hedge fund style with the power of AI
          </div>
        </div>
        <div class="item">
          <div class="title">
            Active Portfolios
          </div>
          <div class="content">
            Active Portfolios
          </div>
        </div>
        <div class="item">
          <div class="title">
            Al Model Portfolios
          </div>
          <div class="content">
            Find the best asset allocation models powered by Al
          </div>
        </div>
        <div class="item">
          <div class="title">
            Model Portfolios
          </div>
          <div class="content">
            Discover the best asset allocation models from our community
          </div>
        </div>
        <div class="item">
          <div class="title">
            Alpha and Beta
          </div>
          <div class="content">
            Create any 401(k) portfolios for more than 80,370 US companies
          </div>
        </div>
        <div class="item">
          <div class="title">
            Investor Clubs
          </div>
          <div class="content">
            Find Investor Clubs matching your investment style
          </div>
        </div>
        <div class="item">
          <div class="title">
            Blogs by Experts
          </div>
          <div class="content">
            Read expert options on interesting market events
          </div>
        </div>
      </div>
    </div>

    <!--    伙伴 媒体    -->
    <div class="huoBan-meiTi-views">
      <div class="title-2-view flex">
        <div class="txt-2">
          Partner
        </div>
        &nbsp;&nbsp;
        <div class="txt-3">
          Media
        </div>

        <div class="icon-views">
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
          <img src="../assets/home/jianTou-icon.png" class="img" alt=""/>
        </div>
      </div>

      <div class="huoBan-views flex justify-between">
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-1.png" />
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-2.png" />
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-3.png" />
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-4.png" />
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-5.png" />
        <img class="huoBan-img" alt="" src="../assets/home/huoBan-6.png" />
      </div>






    </div>



  </div>


  <BottomView></BottomView>
</template>
<script>
import Header1 from "../components/header.vue";
import BottomView from "@/components/BottomView.vue";

export default {
  name: "Home",
  components: {BottomView, Header1},
  data() {
    return {};
  }
}
</script>

<script setup>

</script>
<style scoped lang="scss">

.video-views {
  margin: 2rem 0;
  width: 100%;

  .video-view {
    width: 90%;
    max-height: 500px;
  }

}

.title-1 {
  font-size: 2.6rem;
  font-weight: bold;
  color: #D8A068;
}

.div-1-views {
  padding: 10rem 4rem 3rem;
  width: 90%;

  .msg-views {
    .txt-1 {
      font-size: 2.2rem;
      color: white;
      margin-top: 3rem;
    }

    .img {
      margin-top: 1rem;
      width: 60rem;
      align-self: center;
    }
  }

  .icon-views {
    position: absolute;
    right: 0;
    top: 3rem;

    img {
      width: 4rem;
    }
  }

  .title-2-view {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.5;
    position: relative;

    .txt-2 {
      color: #fff;
    }

    .txt-3 {
      color: #D8A068;
    }
  }

  .youShi-views {
    margin: 2rem 0;

    .item {
      background: url(/src/assets/home/youShi-back.png);
      background-size: 100%;
      background-repeat: no-repeat;
      width: 23%;
      height: 32vw;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      color: #fff;
      font-size: 1.4rem;

      .youShi-icon {
        width: 2rem;
        margin-bottom: 1rem;

      }

      .title {
        height: 5.5rem;
        font-size: 1.7rem;
      }


    }
  }


  .msg-2-views {

    .txt-3 {
      font-size: 3rem;
    }

    .yuanJin-msg-views {
      margin-top: 8rem;

      .yuanJin-msg-views-1 {
        margin-bottom: 8rem;
      }

      .title {
        font-size: 3rem;
        color: #fff;
        margin-bottom: 3rem;
      }

      .left-views {
        flex: 1;
        .chengLuo-button-view {
          color: #fff;
          font-size: 1.8rem;
          font-weight: 500;
          padding: 2rem;
        }


      }

      .right-views {
        flex: 0 0 50%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .yuanJin-img-1 {
          flex: 0 0 48%;
          width: 48%;

        }

        .yuanJin-xueShengNum {
          flex: 1 0;
          margin-top: 1.5rem;
          line-height: 1.5;
          color: #fff;

          .xueShengNum-icon-img {
            width: 3.5rem;
            margin-left: 2rem;
            margin-right: 1rem;
          }

          .xueSheng-txt-1 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-right: 1rem;
          }

          .xueSheng-txt-2 {
            font-size: 1.2rem;
          }
        }

      }


      .ul-item {
        margin-bottom: 3rem;
        display: flex;
        color: #fff;
        line-height: 1.5;
        font-size: 1.6rem;
        font-weight: 400;

        .dian-icon {
          align-self: center;
          width: 2rem;
          margin-right: 2rem;
        }
      }


    }


  }


  .shiChang-jiaoYiYuan-touZhiZhe-views{
    margin-top: 5rem;

    .shiChang-views{
      margin-top: 3rem;
      .item{
        color: #ffffff;
        flex: 0 0 22%;
        margin-bottom: 5rem;
        .title{
          font-size: 1.5rem;
          margin-bottom: 2rem;
        }

        .content{
          font-size: 1.2rem;
          color: #ececec;
        }

      }
    }

  }

  .huoBan-meiTi-views{
    margin-top: 4rem;
    margin-bottom: 5rem;

    .icon-views{
      top: 0rem;
    }

    .huoBan-views{
      margin-top: 5rem;
      .huoBan-img{
        width: 15%;
      }
    }


  }



}


@media screen and (max-width: 768px) {

  .video-views {
    .video-view {
      width: 100%;
      max-height: 450px;
    }

  }


  .div-1-views {
    .msg-views {
      .img {
        width: 90%;
      }
    }

    .title-2-view {
      font-size: 3rem;
    }


    .youShi-views {

      .item {
        font-size: .8rem;

        .title {
          height: 3rem;
          font-size: .9rem;
        }


      }
    }


    .msg-2-views {

      .txt-3 {
        font-size: 1.5rem;
      }

      .yuanJin-msg-views {
        .ul-item {
          font-size: 1rem;
          margin-bottom: 0rem;
        }

        .title {
          font-size: 1.5rem;
          margin-bottom: 1.8rem;
        }


        .left-views {
          .chengLuo-button-view {
            padding: 1rem;
            font-size: .9rem;
          }
        }

        .right-views{
          .yuanJin-xueShengNum{
            .xueShengNum-icon-img {
              width: 1.8rem;
              margin-left: 1rem;
              margin-right: .5rem;
            }
            .xueSheng-txt-1{
              font-size: .9rem;
            }
            .xueSheng-txt-2{
              font-size: .6rem;
            }
          }

        }

      }

    }

  }

}


</style>
