import { createStore } from 'vuex'



const store = createStore({
	state(){
		return {
			isSmall:false,
		}
	},
	mutations: {
		SET_IS_SMALL(state, data) {
			state.isSmall = data;
		},
	},
	actions: {
		SET_IS_SMALL(context, data) {
			context.commit("SET_IS_SMALL",data)
		},

	}
})

var html = document.documentElement;
if(html.clientWidth <= 760){
	store.commit("SET_IS_SMALL",true)
}else{
	store.commit("SET_IS_SMALL",false)
}
window.addEventListener('resize', () => {
	var html = document.documentElement;
	if(html.clientWidth <= 760){
		store.commit("SET_IS_SMALL",true)
	}else{
		store.commit("SET_IS_SMALL",false)
	}

}, false);

export default store
