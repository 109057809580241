<template>
  <div class="top-views">
    <div class="title txtBack">
      <template v-if="selectFlag == 'news'">
        Market Interpretation
      </template>
      <template v-else-if="selectFlag.indexOf('Online') != -1">
        Learning archive
      </template>
      <template v-else>
        Investment Learning
      </template>
    </div>
  </div>


  <div class="def-max-width content-views flex">
    <!--  文章列表  -->
    <div class="list-views"
         :class="[selectFlag == 'news' ? '':'order-1',isSmall ? 'isSmall':'']">
      <div v-show="!isShowWenZhangContent" style="width: 100%;" class="flex flex-direction align-stretch">
        <div v-for="(item,index) in listData" :key="index" class="item item-back flex flex-direction align-stretch">
          <div class="img-u" v-if="item.titleImg">
            <img style="width: 100%;height: 100%;" :src="item.titleImg" alt="">
          </div>
          <div class="item-content flex flex-direction align-start">
            <div class="time">
              <template v-if="item.time">
                {{ item.time }}
              </template>
              <template v-else>
                {{ new Date(item.ntime+'000'-0).toDateString() }}
              </template>
            </div>
            <div @click="onClickShowWenZhangContent(item)" class="title">
              {{item.title ? item.title:item.ntitle}}
            </div>
            <div class="zuoZhe">
              {{item.zuoZhe ? item.zuoZhe:item.nauthor}}
            </div>
            <div @click="onClickShowWenZhangContent(item)" class="show-button item-back">
              Read More ->
            </div>
          </div>
        </div>
        <div v-show="listData.length == 0">
          <el-empty style="" description="NO DATA" />
        </div>
      </div>
      <!--    显示文章具体内容  -->
      <div v-if="isShowWenZhangContent" class="item item-back " style="padding: 2rem">
        <div class="top-back-views">
          <el-icon @click="()=>{
            isShowWenZhangContent = false;
            showWenZhang = null;
          }" style="cursor: pointer;" size="3rem">
            <Back/>
          </el-icon>
        </div>
        <div class="wenZhang-content" v-html="showWenZhang.content">

        </div>
      </div>
    </div>
    <!--    功能列表-->
    <div class="tabs-views" :class="{hide:isSmall}">

<!--   分类   -->
<!--      <div class="type-views item-back" v-if="selectFlag != 'news'">-->
<!--          <div class="title-views">-->
<!--            Categories-->
<!--          </div>-->
<!--      </div>-->

<!--     近期文章 -->
      <div class="news-list-views item-back">
        <div class="title-views">
          Recent Posts
        </div>
        <div class="recent-list-views">
          <div @click="onClickShowWenZhangContent(item)"  v-for="(item,index) in listData.slice(0, 5)" :key="index" class="item">
            {{item.title ? item.title:item.ntitle}}
          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>

import {mapState} from "vuex";
import MarketInterpretation from "@/consts/news/marketInterpretation";
import AmericanEquities from "@/consts/news/AmericanEquities";
import BondSecurities from "@/consts/news/BondSecurities";
import CapitalFunds from "@/consts/news/CapitalFunds";
import CompliancePolicies from "@/consts/news/CompliancePolicies";
import CurrencyMarkets from "@/consts/news/CurrencyMarkets";
import PortfolioStrategies from "@/consts/news/PortfolioStrategies";
import VirtualCurrencies from "@/consts/news/VirtualCurrencies";
import WorldwideEconomics from "@/consts/news/WorldwideEconomics";
import OnlineLearningRecords from "@/consts/news/Online-LearningRecords";
import onlinePortfolioRecords from "@/consts/news/Online-PortfolioRecords";


export default {
  name: "moBan",
  data() {
    return {
      selectFlag: 'news',
      tableData: null,
      isShowWenZhangContent: false,//是否是显示文章详细内容
      showWenZhang: null,
      listData:[]
    }
  },
  computed: {
    ...mapState(['isSmall'])
  },
  watch: {
    '$route.query': {
      handler(newQuery) {
        console.log('查询参数发生变化', newQuery);
        console.log('查询参数发生变化-list', this.listData.slice(0, 5));
        if (this.$route.query.flag) {
          this.selectFlag = this.$route.query.flag;
        }
        this.listData = [];

        setTimeout(()=>{
          if(this.selectFlag == 'news'){
            this.listData = MarketInterpretation;
          }else if(this.selectFlag == 'AmericanEquities'){
            this.listData = AmericanEquities;
          }else if(this.selectFlag == 'CapitalFunds'){
            this.listData = CapitalFunds;
          }else if(this.selectFlag == 'BondSecurities'){
            this.listData = BondSecurities;
          }else if(this.selectFlag == 'CurrencyMarkets'){
            this.listData = CurrencyMarkets;
          }else if(this.selectFlag == 'VirtualCurrencies'){
            this.listData = VirtualCurrencies;
          }else if(this.selectFlag == 'WorldwideEconomics'){
            this.listData = WorldwideEconomics;
          }else if(this.selectFlag == 'PortfolioStrategies'){
            this.listData = PortfolioStrategies;
          }else if(this.selectFlag == 'CompliancePolicies'){
            this.listData = CompliancePolicies;
          }else if(this.selectFlag == 'Online-LearningRecords'){
            this.listData = OnlineLearningRecords;
          }else if(this.selectFlag == 'Online-PortfolioRecords'){
            this.listData = onlinePortfolioRecords;
          }
        },500)
      },
      immediate: true // 立即在初始渲染时调用 handler
    }
  },
  methods:{
    //点击显示文章内容
    onClickShowWenZhangContent(item) {
      this.isShowWenZhangContent = true;
      this.showWenZhang = item;
    }
  },
  created() {
    if (this.$route.query.flag) {
      this.selectFlag = this.$route.query.flag;
    }
  }
}
</script>

<style scoped lang="scss">
.top-views {
  background: url(../../assets/views/aboutUs/about-back.png);
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  width: 100%;
  height: 20.833vw;
  position: relative;

  .title {
    position: absolute;
    left: 10%;
    top: 40%;
    font-size: 5rem;
    color: #bf8141;
  }
}

.content-views {
  margin-top: 6rem;



  .item-back {
    background-image: linear-gradient(90deg, #ebb986, #bf8141);
    margin-bottom: 2rem;
  }

  .list-views {
    flex: 0 0 65%;
    width: 0;
    margin-right: 3rem;

    &.order-1 {
      order: 1;
      margin-left: 3rem;
      margin-right: 0;
    }

    .top-back-views {
      margin-bottom: 2rem;
    }

    ::v-deep .wenZhang-content {
      overflow: hidden;
      width: 100%;
      img {
        max-width: 100% !important;
        width: 100%;
        position: inherit !important;
      }

    }

    &.isSmall{
      flex: 1 0 100%;
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    .item{
      max-width: 100%;

        .img-u {
          width: 100%;
          height: 347px;
        }
      .item-content{
        padding: 3rem 2rem;
        font-size: 1.5rem;
        line-height: 2;

        .time{
          font-size: 1.3rem;
        }

        .title{
          font-size: 2rem;
          margin: 1rem 0;
        }

        .zuoZhe{
          font-size: 1.6rem;
          font-weight: 500;
        }


        .show-button{
          margin-top: 2rem;
          border: 1px solid #BF8D58;
          padding: 1rem 3rem;
          font-weight: 400;
          border-radius: .3rem;
          cursor: pointer;
        }

      }
    }
  }

  .tabs-views {
    flex: 1 0;

    .item-back{
      padding: 2rem;
    }

    .title-views{
      font-size: 2rem;
      padding: 1.5rem 0;
      margin-bottom: 3rem;
      position: relative;
      border-bottom: 1px solid white;
      &:after{
        position: absolute;
        content: "";
        background: black;
        width: 4rem;
        height: 1px;
        left: 0;
        bottom: -1px;
      }

    }

    .recent-list-views{
      font-size: 1.6rem;
      font-weight: bold;
      .item{
        margin-bottom: 2rem;
        cursor: pointer;
      }
    }

  }

}

</style>
