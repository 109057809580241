<template>
  <div class="top-views">
    <div class="title">
      Alpha Picks
    </div>
  </div>


  <div class="def-max-width content-views">
    <div class="tabs-views">
      <div class="tabs-items-views flex">
        <div @click="()=>{
          isShowWenZhangContent = false;
          tabsFlag = 'Analysis';
        }" class="tabs-item" :class="{active:tabsFlag == 'Analysis'}">
          Analysis
        </div>
        <div @click="()=>{
          isShowWenZhangContent = false;
          tabsFlag = 'Portfolio';
        }" class="tabs-item" :class="{active:tabsFlag == 'Portfolio'}">
          Portfolio
        </div>
        <div @click="()=>{
          isShowWenZhangContent = false;
          tabsFlag = 'Performance';
        }" class="tabs-item" :class="{active:tabsFlag == 'Performance'}">
          Performance
        </div>
      </div>
    </div>
    <!--   Analysis -->
    <div class="wenZhang-views tabs-content-views" v-show="tabsFlag == 'Analysis'">
      <div v-show="!isShowWenZhangContent" class="wenZhang-list-views">
        <div class="wenZhang-header-views">
          Recent Posts
        </div>
        <div v-for="(item,index) in AlphaPicks" :key="index" class="wenZhang-item">
          <img class="wenZhang-img" alt="" :src="item.titleImg">
          <div class="wenZhang-title-views">
            <div @click="onClickShowWenZhangContent(item)" class="title txtBack">
              {{ item.title }}
            </div>
            <div v-if="item.zuoZhe" class="zuoZhe">
              {{ item.zuoZhe }}
            </div>
          </div>
        </div>
      </div>
      <!--    显示文章具体内容  -->
      <div v-if="isShowWenZhangContent" class="wenZhang-content-views">
        <div class="top-back-views">
          <el-icon @click="()=>{
            isShowWenZhangContent = false;
            showWenZhang = null;
          }" style="cursor: pointer;" size="3rem">
            <Back/>
          </el-icon>
        </div>
        <div class="wenZhang-content" v-html="showWenZhang.content">

        </div>
      </div>
    </div>

    <!--  Portfolio  -->
    <div class="portfolio-views" v-show="tabsFlag == 'Portfolio' || tabsFlag == 'Performance'">
      <div class="total-views">
        <div class="title txtBack">
          Portfolio Total Return
        </div>
        <div class="total-content-views flex">
          <div class="item">
            <div>AlphaPicks</div>
            <div class="total-num">
              +129.83%
            </div>
          </div>
          <div class="item">
            <div>S&P 500</div>
            <div class="total-num">
              +47.58%
            </div>
          </div>
        </div>
      </div>
<!--      表格-->
      <div class="tables-views" v-show="tabsFlag == 'Portfolio'">
        <el-table style="width: 100%" :data="tableDataPortfolio">
          <el-table-column fixed label="Company" prop="Company"></el-table-column>
          <el-table-column label="Symbol" prop="Symbol"></el-table-column>
          <el-table-column label="Picked" prop="Picked"></el-table-column>
          <el-table-column label="Return" prop="Return"></el-table-column>
          <el-table-column label="Return" prop="Sector"></el-table-column>
          <el-table-column label="Rating" prop="Rating">
            <template #default="scope">
              <span class="rating-txt-view color3" v-if="scope.row.Rating == 'HOLD'">HOLD</span>
              <span class="rating-txt-view color2" v-if="scope.row.Rating == 'BUY'">BUY</span>
              <span class="rating-txt-view color1" v-if="scope.row.Rating == 'STRONG BUY'">STRONG BUY</span>
            </template>
          </el-table-column>
          <el-table-column label="Rating" prop="Holding"></el-table-column>

        </el-table>
      </div>
      <div class="tables-views" v-show="tabsFlag == 'Performance'">
        <el-table style="width: 100%" :data="tableDataPerformance">
          <el-table-column fixed label="Company" prop="Company"></el-table-column>
          <el-table-column label="Symbol" prop="Symbol"></el-table-column>
          <el-table-column label="Picked" prop="Picked"></el-table-column>
          <el-table-column label="Purchase" prop="Purchase"></el-table-column>
          <el-table-column label="Sell Price" prop="Sell_Price"></el-table-column>
          <el-table-column label="Return" prop="Return"></el-table-column>
          <el-table-column label="SPY" prop="SPY"></el-table-column>
          <el-table-column label="Diff" prop="Diff"></el-table-column>

        </el-table>
      </div>

    </div>

  </div>

</template>
<script>
import AlphaPicks from "@/consts/AlphaPicks";
import alphaPicksTablePortfolio from "@/consts/AlphaPicks-table-portfolio";
import alphaPicksTablePerformance from "@/consts/AlphaPicks-table-Performance";

export default {
  name: "index",
  data() {
    return {
      tabsFlag: 'Analysis',
      AlphaPicks: AlphaPicks,
      tableDataPortfolio: alphaPicksTablePortfolio,
      tableDataPerformance: alphaPicksTablePerformance,
      isShowWenZhangContent: false,//是否是显示文章详细内容
      showWenZhang: null,
    }
  },
  methods: {
    //点击显示文章内容
    onClickShowWenZhangContent(item) {
      this.isShowWenZhangContent = true;
      this.showWenZhang = item;
    }
  },
}
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
@import "../../common/common";

.top-views {
  background: url(../../assets/views/aboutUs/about-back.png);
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  width: 100%;
  height: 20.833vw;
  position: relative;

  .title {
    position: absolute;
    font-weight: bold;
    left: 10%;
    top: 40%;
    font-size: 5rem;
    //color: #bf8141;
    @extend .txtBack
  }
}

.content-views {
  margin-top: 6rem;

  .tabs-views {
    border-bottom: 1px solid white;

    .tabs-item {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 400;
      @extend .txtBack;
      position: relative;
      cursor: pointer;
      margin-right: 1rem;

      &.active:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: #bf8141;
      }
    }

  }

  .tabs-content-views {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;

  }


  .wenZhang-header-views {
    font-weight: 400;
    font-size: 1.9rem;
    @extend .txtBack;
    border-bottom: 1px solid white;


  }

  .wenZhang-list-views {
    width: 100%;
  }

  .wenZhang-content-views {
    width: 100%;
    background-color: #fff;
    padding: 2rem;

    .top-back-views {
      margin-bottom: 2rem;
    }

    ::v-deep .wenZhang-content {
      overflow: hidden;
      img {
        max-width: 100% !important;
        width: 100%;
        position: inherit !important;
      }

    }
  }


  .wenZhang-item {
    padding: 1.6rem 0;
    display: flex;


    .wenZhang-img {
      width: 20rem;
      border-radius: .5rem;
      margin-right: 1rem;
    }

    .wenZhang-title-views {
      display: flex;
      flex-direction: column;
      padding: 2rem 0;
      font-size: 1.4rem;

      .title {
        cursor: pointer;

      }

      .zuoZhe {
        color: #fff;
        margin-top: 2rem;


      }

    }

  }


  .portfolio-views {
    .total-views {
      margin: 4rem 0;
      font-size: 1.8rem;

      .title {
        font-weight: bold
      }

      .total-content-views {
        margin-top: 3rem;

        .item{
          margin-right: 4rem;
          color: #fff;
          .total-num{
            margin-top: 1rem;
            font-size: 2rem;
            font-weight: bold;
            @extend .txtBack;
          }

        }

      }

    }

    .tables-views{
      width: 100%;

      ::v-deep .rating-txt-view{
        padding: .5rem;
        width: 10rem;
        text-align: center;
        display: inline-block;

        &.color3{
          background: #EBD517;
        }
        &.color2{
          background: #33732E;
          color: #fff;
        }
        &.color1{
          background: #1C4019;
          color: #fff;
        }

      }


    }

  }


}

</style>
